body {


  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #037cc1;

  display: flex;
  flex-direction: column;

}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-dark {
  background-color: #037cc1;
}


.modal-header .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0;
  padding: 0;
  z-index: 10;
}

h1,
.h1 {
  color: white;
}

.row-cards {
  padding-top: 1rem;
}

.font-color-black {
  color: #000;
  font-size: 5rem;
}

.font-color-white {
  color: #fff;
}

.early-access-tag {
  color: #fff;
  font-size: 3rem;
}

.color-white {
  background-color: #fff;
}

.color-tan {
  background-color: #f6e2b3;
}

.color-tbl-header {
  background-color: #f4f6fa;
}

.color-blue {
  background-color: #c7dbf3;
}

a:hover {
  text-decoration: none;
}

.note-height {
  max-height: 35rem;
}


.table thead th {
  background-color: #f6e2b3;
}

.edit-button {
  margin-left: auto;
  order: 2;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card {
  margin-top: 1rem;
}


.infinite-road {
  position: relative;
  width: 800px;
  height: 160px;
  background: #525252;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: perspective(500px) rotateX(30deg)
}

.infinite-road:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #fff, 0%, #fff 40%, #525252 40%, #525252 100%);
  background-size: 200px;
  animation: animate 2.0s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0px;
  }

  100% {
    background-position: -200px;
  }
}

.truck-driving {
  width: 2302px;
  height: 511px;
  background-image: url(https://i.imgur.com/P9pWF5C.png);
  animation: moveX .2s steps(2) infinite;
}

@keyframes moveX {
  from {
    background-position-x: 0px;
  }

  to {
    background-position-x: -4604px;
  }
}

.masthead {
  height: 100vh;
  min-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.masthead-with-pic {
  height: 100vh;
  min-height: 500px;
  background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.roadbg {
  background-color: #55464e;
}

.cFeatures {
  background-color: #fff;
}

.jumbotron-heading {
  color: #7E6651;
  font-size: 5rem;
}

.jumbotron-lead {
  font-size: 1rem;
}

.jumbotron-header {
  color: white;
  font-size: 5rem;
}


.jumbotron-bg {
  background-image: url('https://i.imgur.com/UdDR1sY.png')
}

.build-loads-container {
  margin: 15px auto;
  padding: 50px;
  max-width: 850px;
  background-color: #037cc1;

  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
}

.hide-me {
  display: none;
}

.better-box-shadow {
  box-shadow: rgb(35 46 60) 0 2px 4px 0
}

.bigger-box-shadow {
  box-shadow: rgb(0, 0, 0) 0 6px 12px 0
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: .2;
}

.placeholder.btn::before {
  display: inline-block;
  content: ""
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: .1;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: .1;
  }
}

.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .9) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .9) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.placeholder:not(.btn):not([class*=bg-]) {
  background-color: currentColor !important;
}

.placeholder:not(.avatar):not([class*=card-img-]) {
  border-radius: 4px;
}

.form-control::-webkit-input-placeholder {
  color: #a5a9b1;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #a5a9b1;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #a5a9b1;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #a5a9b1;
  opacity: 1;
}

.form-control::placeholder {
  color: #a5a9b1;
  opacity: 1;
}

@media (prefers-reduced-motion:reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control {
  padding: 1rem .75rem;
}

.form-floating>.form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-floating>.form-control::-moz-placeholder {
  color: transparent;
}

.form-floating>.form-control:-ms-input-placeholder {
  color: transparent;
}

.form-floating>.form-control::-ms-input-placeholder {
  color: transparent;
}

.form-floating>.form-control::placeholder {
  color: transparent;
}

.form-floating>.form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.form-floating>.form-control:not(:-ms-input-placeholder)~label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.form-floating>.form-control:-webkit-autofill~label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.shipper {
  border: none;
  box-shadow: none;
  border-left: 0.25rem solid rgb(116, 184, 22)
}

.receiver {
  border: none;
  box-shadow: none;
  border-left: 0.25rem solid rgb(214, 57, 57)
}

/* 
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: rgba(126, 239, 104, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
  outline: 0 none;
  color: "https://stackoverflow.com/questions/14820952/change-bootstrap-input-focus-blue-glow"
}

*/


.jumbo-bg2 {
  background: linear-gradient(to bottom, rgba(126, 102, 81, 1), rgba(180, 153, 130, .2)), url("https://fltbd-bucket.s3.us-east-2.amazonaws.com/fltbd_jumbo-bg2.jpg") no-repeat center center / cover;
}

.jumbo-login {
  background: linear-gradient(to top, rgba(126, 102, 81, 1), rgba(0, 0, 0, 0), rgba(126, 102, 81, 1)), url("https://fltbd-bucket.s3.us-east-2.amazonaws.com/fltbd_jumbo-login.jpg") no-repeat center center / cover;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  padding-bottom: 60px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
}

footer {
  position: absolute;
  width: 100%;
  height: 200px;
  left: 0;
  bottom: 0;

}

.footer-container {
  margin: 15px auto;
  padding: 20px;
  max-width: 850px;
  color: white
}


.jumbotron {
  padding-top: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 0rem
}

.float-right {
  float: right !important;

}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(72, 94, 144, 0.16);
}

.demo-icon-preview i,
.demo-icon-preview svg {
  width: 3rem;
  height: 3rem;
  font-size: 15rem;
  stroke-width: 1.5;
  margin: 0 auto;
  display: block;
}

.btn svg {
  stroke-width: 2.5px
}


.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.mg-l-5 {
  margin-left: 5px
}

.modal-content .close {
  font-weight: 300;
  font-size: 28px;
  line-height: 0.87;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #1b2e4b;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #1b2e4b;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.input-group-text {
  font-weight: bold;
}

.form-label {
  font-weight: bold
}


.blinking-alert {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


.custom-option {
  transition: background 60ms;
}

.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}

.custom-option.custom-select__option--is-focused {
  background: none;
}

.custom-option.custom-select__option--is-selected {
  background: #2684FF;
}


.lottie-anim {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.dropdown-view {
  background-color: #206bc4;
  font-weight: bold;
}

.dropdown-view:hover {
  background-color: #1a529a;
  font-weight: bold;
}

.dropdown-edit {
  background-color: #2fb344;
  font-weight: bold;
}

.dropdown-edit:hover {
  background-color: #267330;
  font-weight: bold;
}
.dropdown-documents {
  background-color: #4299e1;
  font-weight: bold;
}
.dropdown-documents:hover {
  background-color: #1a73e8;
  font-weight: bold;
}
.dropdown-copy {
  background-color: #ae3ec9;
  font-weight: bold;
}
.dropdown-copy:hover {
  background-color: #8a2fb4;
  font-weight: bold;
}
.dropdown-delete {
  background-color: #df3432;
  font-weight: bold;
}
.dropdown-delete:hover {
  background-color: #b5201f;
  font-weight: bold;
}

.reactTable table {
  width: 100%;
  border-spacing: 0;
}

.reactTable table tr:last-child td {
  border-bottom: 0;
}

.reactTable-th,
.reactTable-td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 0px solid black;
  width: 1%;
}

.reactTable table td.collapse {
  width: 0.0000000001%;
}

.reactTable table th:last-child,
.reactTable table td:last-child {
  border-right: 0;
}


.slider {
  -webkit-appearance: none;
  width: 52%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  margin-top: 20px;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}


.permanent-marker-regular {
  font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
}
.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}
.kalam-light {
  font-family: "Kalam", cursive;
  font-weight: 300;
  font-style: normal;
}

.kalam-regular {
  font-family: "Kalam", cursive;
  font-weight: 400;
  font-style: normal;
}

.kalam-bold {
  font-family: "Kalam", cursive;
  font-weight: 700;
  font-style: normal;
}

.walter-turncoat-regular {
  font-family: "Walter Turncoat", cursive;
  font-weight: 400;
  font-style: normal;
}
.rock-salt-regular {
  font-family: "Rock Salt", cursive;
  font-weight: 400;
  font-style: normal;
}
.homemade-apple-regular {
  font-family: "Homemade Apple", cursive;
  font-weight: 400;
  font-style: normal;
}

.gloria-hallelujah-regular {
  font-family: "Gloria Hallelujah", cursive;
  font-weight: 400;
  font-style: normal;
}
.itim-regular {
  font-family: "Itim", cursive;
  font-weight: 400;
  font-style: normal;
}

